.cookies {
  display: grid;
  place-content: center;
  position: fixed;
  width: 100%;
  height: 100px;
  bottom: 0;
  left: 0;
  align-items: 'center';
  background: 'white';
  z-index: 99;
  /* border-top: 2px solid var(--TLC-web-grey); */
}

p {
  padding-right: 2%;
}