.title {
  color: var(--TLC-drkgraytxt);
  font-weight: 400;
  font-size: 16px;
}

.text {
  font-size: 18px;
  color: var(--TLC-bodytxt)
}

.completed {
  font-size: 18px;
  color: var(--TLC-web-grey);
  text-decoration: line-through;
}

.outlined {
  border-top: 2px solid var(--TLC-web-grey);
  border-bottom: 2px solid var(--TLC-web-grey);
}

.link {
  color: var(--TLC-gray);
  cursor: pointer;
  text-decoration-color: var(--TLC-gray);
}

.outlined li:not(:last-child) {
  border-bottom: 2px solid var(--TLC-web-grey)
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

@media only screen and (max-width: 768px) {
  .mobileFlex {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }

}