.emailContainer {
  /*  background-color: #f5f5f5;
  border: 2px solid var(--TLC-blue-stroke); */
  display: inherit;
  width: 100%;
}

.shareLink {
  text-overflow: ellipsis;
}

.shareLink div {
  background-color: white !important;
}

.absBox {
  margin-bottom: 2%;
  padding-top: 350px;
}

.nameBox {
  color: var(--TLC-bodytxt);
  margin-top: 2%
}

.deceasedName {
  font-size: 48px;
  font-family: 'EB Garamond', serif;
}

.deceasedLifespan {
  font-size: 24px;
  font-family: 'EB Garamond', serif;
}

.emailSubj {
  font-size: 32px;
  color: var(--TLC-bodytxt);
}

.emailBody {
  font-size: 18px;
  color: var(--TLC-bodytxt);

}

.previewModal {
  position: absolute;
  overflow-y: auto;
  width: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--TLC-webbg);
  padding: 1%;
}

.previewBox {
  position: absolute;
  bottom: 0;
  left: 5%;
  color: var(--TLC-web-grey);
}

.previewBoxBlack {
  position: absolute;
  bottom: 0;
  left: 15%;
  color: var(--TLC-bodytxt);
}

.previewName {
  font-size: 48px;
  font-family: 'EB Garamond', serif;
}

.previewLifespan {
  font-size: 24px;
  font-family: 'EB Garamond', serif;
}

.previewSubj {
  font-size: 18px;
  color: var(--TLC-bodytxt);
}

.previewBody {
  font-size: 14px;
  color: var(--TLC-bodytxt);
}