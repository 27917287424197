.isflex {
  display: flex;
}

.mandatory {
  color: var(--TLC-gray);
}

.input {
  height: 40px;
}

.input p {
  margin: 0;
  color: var(--TLC-pink) !important;
  background-color: transparent;
}

.error {
  color: var(--TLC-pink)
}

.link {
  cursor: pointer;
  color: var(--TLC-gray);
  text-decoration-color: var(--TLC-gray);
}

.link:hover {
  color: var(--TLC-gray)
}

.border {
  border-bottom: 2px solid var(--TLC-gray);
}

.rfmTablehead {
  font-size: 16px !important;
  color: var(--TLC-drkgraytxt);
  border-bottom: 1px solid #CCCCCC !important;
}

.card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--TLC-webbg);
}

.tableRfm_body {
  border-color: #CCCCCC;
}

.addressTextContainer {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 725px) {
  .isflex {
    flex-direction: column;
  }

  .col {
    flex-direction: column;
  }

  .inputLabel {
    margin: 0;
  }

  .addressTextContainer {
    width: 200px;
  }

}