.container {
  background: #FCFAF8;
  /* border: 1px solid var(--TLC-web-grey); */
  margin-top: 2%;
  padding: 1%
}

.mandatory {
  color: var(--TLC-gray);
}

.input {
  height: 40px;
}

.input p {
  margin: 0;
  color: var(--TLC-pink) !important;
  background-color: transparent;
}

.container2 {
  margin-top: 2%;
  padding: 1%
}

.isflex {
  display: flex;
}

.col {
  display: flex;
  width: 40%;
}

.label {
  padding-bottom: 1%;
  color: var(--TLC-drkgraytxt);
}

.inputLabel {
  font-size: 16px;
  font-weight: bold;
  width: 80px;
  white-space: break-spaces;
}

.inputMarginTop {
  margin-top: 2%
}

.flexCol {
  display: contents;
  flex-direction: column;
}

.listContainer {
  border-top: 2px solid var(--TLC-blue-stroke);
  border-bottom: 2px solid var(--TLC-blue-stroke);
}

.listItem {
  border-bottom: 1px solid var(--share-border);
}

@media screen and (max-width: 725px) {
  .container {
    width: 100%;
  }

  .isflex {
    flex-direction: column;
  }

  .col {
    flex-direction: column;
  }

  .inputLabel {
    margin: 0;
  }
}