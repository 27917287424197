.header {
  font-family: 'EB Garamond', serif;
  font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 600;
  color: var(--TLC-bodytxt);
}

.link {
  color: var(--TLC-gray);
  text-decoration: none;
}

.name {
  font-weight: 600;
}

.divider {
  margin-top: 2%;
  border: 1px solid var(--TLC-web-grey)
}