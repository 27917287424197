.nav {
  height: 60px;
  background: none;
  box-shadow: none;
}

.links {
  font-family: 'EB Garamond', serif;
  margin-right: 2%;
  text-decoration: none;
  color: var(--TLC-drkgraytxt);
  font-size: 14px;
}

.tabs {
  margin: auto;
  border-bottom: 2px solid var(--TLC-gray);
}

.logodiv {
  max-width: 214.78px;
}

.active {
  color: var(--TLC-sharelnk)
}

.menuItem {
  font-family: 'EB Garamond', serif;
  font-size: 16px;
  font-weight: 400;
  color: var(--TLC-bodytxt);
}