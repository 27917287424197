.container {
  min-height: 400px
}

.icon {
  color: var(--TLC-gray)
}

.centerIcon {
  justify-content: center;
  align-items: center;
  display: flex;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}

.mandatory {
  color: var(--TLC-gray);
}

.center {
  display: inherit;
  justify-items: center;
  align-items: center;
}

.isflex {
  display: flex;
}

.link {
  color: var(--TLC-gray);
  cursor: pointer;
  text-decoration-color: var(--TLC-gray);
}

.input {
  margin-bottom: 0;
}

.input div {
  background-color: white;
}

.input p {
  margin: 0;
  background-color: var(--TLC-webbg-gray);
}

.col {
  display: flex;
  width: 40%;
  margin-right: 5%;
}

.addressCol {
  width: 40%;
  margin-right: 5%;
}

.label {
  font-size: 16px;
  padding-bottom: 1%;
  color: var(--TLC-drkgraytxt);
}

.inputLabel {
  display: inherit;
  flex-wrap: wrap;
  font-size: 16px;
  width: 100px;
  white-space: break-spaces;
  align-content: center;
  justify-content: center;
}

.addressLabel {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  width: 100px;
  white-space: break-spaces;
  justify-content: center;
}

.listContainer {
  border-top: 2px solid var(--TLC-web-grey);
  border-bottom: 2px solid var(--TLC-web-grey);
}

.listItem {
  width: 100%;
  border-bottom: 1px solid var(--share-border);
}

@media only screen and (max-width: 768px) {
  .isflex {
    flex-direction: column;
  }

  .col {
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  .addressCol {
    width: 100%;
    margin: 0;

  }

  .inputLabel {
    display: inherit;
    flex-wrap: wrap;
    font-size: 16px;
    white-space: break-spaces;
    align-content: flex-end;
    justify-content: flex-start;
  }

  .centerIcon {
    display: inherit;
    justify-content: flex-start;
    align-content: flex-start;
  }

  .addressLabel {
    justify-content: flex-start;
  }
}