body {
  margin: 0;
  font-family: 'Kumbh Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--TLC-flushpink);
  overflow-y: scroll;

}

.optional {
  color: var(--TLC-web-grey);
}

input {
  font-size: 18px;
}

label {
  font-size: 16px;
}

.main {
  font-family: 'Kumbh Sans', sans-serif;
}

.hr {
  width: 100%;
  border: 0;
  border-bottom: 2px solid var(--TLC-gray);
}

.text {
  font-size: 18px
}

.textBold {
  font-size: 18px;
  font-weight: 700;
  color: var(--TLC-drkgraytxt);
}

.sectionText {
  color: var(--TLC-drkgraytxt);
  font-size: 18px;
}

.sectionTextBold {
  color: var(--TLC-drkgraytxt);
  font-size: 18px;
  font-weight: 700;
}

.sectionTitle {
  font-family: 'EB Garamond', serif;
  color: var(--TLC-drkgraytxt);
  font-weight: bold;
  font-size: 20px;
}

.centerText {
  display: flex;
  align-items: center;
}

:root {
  --TLC-white: #ffffff;
  --TLC-black: #000000;
  --TLC-webbg: #f8f4f0;
  --TLC-yellow: #FCFAED;
  --TLC-yellow-stroke: #FFF5B7;
  --TLC-drkgraytxt: #707070;
  --TLC-gray: #545454;
  --cart-action-button: #fcedf0;
  --checkout-hover-color: #de3759;
  --TLC-blue: #edf2fc;
  --TLC-blulnk: #6397ff;
  --TLC-lightblu: #EDFBFC;
  --TLC-sharelnk: #cbdcfd;
  --TLC-blue-stroke: #B7CAEF;
  --TLC-bodytxt: #4e4e4e;
  --TLC-pink: #EA4F6F;
  --TLC-flushpink: #FCEDF0;
  --TLC-web-grey: #CCCCCC;
}

.text-input {
  background-color: white;
  border: 1px solid #edf2fc;
  border-radius: 3px
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: var(--TLC-gray) !important;
}

.MuiClockPointer-root {
  background-color: var(--TLC-gray) !important;
}

.MuiClockPointer-thumb {
  background-color: var(--TLC-gray) !important;
  border: 16px solid var(--TLC-gray) !important;
}

.MuiClock-pin {
  background-color: var(--TLC-gray) !important;
}

input[type=time]::-webkit-datetime-edit-hour-field:focus,
input[type=time]::-webkit-datetime-edit-minute-field:focus,
input[type=time]::-webkit-datetime-edit-second-field:focus,
input[type=time]::-webkit-datetime-edit-ampm-field:focus {
  background-color: var(--TLC-gray);
}

input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="time"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-clear-button {
  display: none;
}