.toolbar {
  margin-top: 5px;
}

.tableheader {
  font-size: 16px ;
  font-weight: 400;
  color: var(--TLC-bodytxt);
}

.sortFlex {
  display: flex;
  flex-direction: column;
}

.headers {
  color: var(--TLC-drkgraytxt);
}

.sortLabel {
  margin-left: 2%;
}

.link {
  color: var(--TLC-gray);
}

.outlined {
  border-top: 2px solid var(--TLC-gray);
}