.header {
  font-family: 'EB Garamond', serif;
  font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 600;
}

.link {
  color: var(--TLC-gray);
  text-decoration: none;
}

.password::placeholder {
  color: var(--TLC-sharelnk)
}

.icon {
  color: var(--TLC-sharelnk)
}

.input p {
  margin: 0;
  background-color: transparent;
}

.error {
  font-size: 16px;
  height:30px;
  color: var(--TLC-pink)
}