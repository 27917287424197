.footer {
  /* background-color: #EDFBFC; */
  background-color: #f2f2f2;
}

.nav {
  font-size: 16px;
  color: var(--TLC-drkgraytxt);
}

.link {
  text-transform: none;
  text-decoration: none;
  color: var(--TLC-drkgraytxt);
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #DCDCDC;
}

.notice a {
  color: #DCDCDC
}