.card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--TLC-webbg);
}

.mandatory {
    color: var(--TLC-gray);
}

.modalTitle {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 2rem;
}