.btnPrimary {
  color: var(--TLC-white);
  background: var(--TLC-gray);
  border: 1px solid var(--TLC-gray);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
}

.btnSecondary {
  color: var(--TLC-bodytxt);
  background: var(--TLC-white);
  border: 1px solid var(--TLC-gray);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
}

.btnPrimary:hover,
.btnSecondary:hover {
  box-shadow: none;
  background: var(--TLC-gray);
  background-color: var(--TLC-gray);
  color: var(--TLC-white);
  border-radius: 3px;
}

.btnTransparent {
  color: var(--TLC-bodytxt);
  border: 1px solid var(--TLC-gray);
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
}

.btnLinks {
  font-weight: 400;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: var(--TLC-bodytxt);
  font-size: 16px;
}


.btnSort {
  color: var(--TLC-bodytxt);
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  margin: 0;
}

.btnTable {
  color: var(--TLC-gray);
  text-decoration: underline;
  margin-top: 0;
  margin-bottom: 2px;
  background-color: transparent;
}

.btnTemplateSelected {
  border: 2px solid var(--TLC-gray)
}