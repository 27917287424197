.tablehead {
  color: var(--TLC-drkgraytxt);
  font-size: 16px;
}

.tableheadTxt {
  color: var(--TLC-drkgraytxt);
  font-size: 18px;
}

.deceasedName {
  font-size: 18px;
  font-weight: 700;
  color: var(--TLC-drkgraytxt);
}

.rfmName {
  font-size: 18px;
  color: var(--TLC-bodytxt)
}

.initial {
  font-family: 'EB Garamond', serif;
  color: var(--TLC-drkgraytxt);
  font-size: 20px;
}

.msgSender {
  font-family: 'EB Garamond', serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--TLC-bodytxt)
}

.msgDate {
  font-size: 14px;
  color: var(--TLC-drkgraytxt)
}