.header {
  font-family: 'EB Garamond', serif;
  font-size: calc(24px + (32 - 24) * ((100vw - 300px) / (1920 - 300)));
  font-weight: 600;
}

.input {
  background-color: white;
}

.link {
  color: var(--TLC-gray);
  text-decoration: none;
}

.name {
  font-weight: 600;
}

.outlined {
  border-top: 2px solid var(--TLC-gray);
  border-bottom: 2px solid var(--TLC-gray);
}

.download {
  color: var(--TLC-bodytxt);
}