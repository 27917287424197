.toolbar {
  margin-top: 5px;
}

.sortFlex {
  display: flex;
  flex-direction: column;
}

.headers {
  font-size: 18px;
  color: var(--TLC-drkgraytxt);
}

.label {
  font-size: 18px;
  color: var(--TLC-bodytxt);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.deceased {
  font-size: 18px;
  color: var(--TLC-drkgraytxt);
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sortLabel {
  margin-left: 2%;
}

.link {
  cursor: pointer;
  color: var(--TLC-gray);
  text-decoration-color: var(--TLC-gray);
}

.archived {
  align-items: center;
}

.outlined {
  border-top: 2px solid var(--TLC-gray);
  border-bottom: 2px solid var(--TLC-gray);
}

.rfmModal {
  position: absolute;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--TLC-webbg);
  padding: 1%;
}

.card {
  width: 90%;
  margin: auto;
  padding-bottom: 2%;
  border: none;
  box-shadow: none;
  background: transparent;
}

.bodyText {
  font-size: 18px;
  color: var(--TLC-bodytxt);
}