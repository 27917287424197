.container {
}

.eventContainer {
  background: #FCFAF8;
  border: 1px solid var(--TLC-web-grey);
  padding: 2%
}

.blueSq {
  height:  20px;
  width: 20px;
  border-radius:4px;
  background-color: var(--TLC-gray);
}

.mandatory {
  color: var(--TLC-gray);
}

input {
  height: 40px
}

.input p {
  margin: 0;
  color: var(--TLC-pink) !important;
  background-color: transparent;
}

.text {
  font-size: 16px;
  color: var(--TLC-drkgraytxt);
}

.labelText {
  font-size: 16px;
  color: var(--TLC-drkgraytxt);
  font-weight: bold;
}

.dateInput {
  background-color: white;
  width: 365px;
}

.link {
  color: var(--TLC-gray);
  cursor: pointer;
}

.isflex {
  display: flex;
}

.col {
  display: flex;
  width: 40%;
}

.label {
  font-size: 16px;
  padding-bottom: 1%;
  color: var(--TLC-drkgraytxt);
}

.inputLabel {
  font-size: 16px;
  font-weight: bold;
  width: 80px;
  white-space: break-spaces;
}

.listContainer {
  border-top: 2px solid var(--TLC-blue-stroke);
  border-bottom: 2px solid var(--TLC-blue-stroke);
}

.listItem {
  border-bottom: 1px solid var(--share-border);
}

.download {
  color: var(--TLC-bodytxt);
}

.MuiButtonBase-root {
  color: var(--TLC-gray) !important;
}